import React from 'react'
import styles from '../styles/Awards.module.scss'
import PaulBAward01Img from '../assets/awards/awards_paulb_fall_bloodbowl/paulb_fall_bloodbowl01.png'
import PaulBAward02Img from '../assets/awards/awards_paulb_fall_bloodbowl/paulb_fall_bloodbowl02.png'
import PaulBAward03Img from '../assets/awards/awards_paulb_fall_bloodbowl/paulb_fall_bloodbowl03.png'
import PaulBAward04Img from '../assets/awards/awards_paulb_fall_bloodbowl/paulb_fall_bloodbowl04.png'
import PaulBAward05Img from '../assets/awards/awards_paulb_fall_bloodbowl/paulb_fall_bloodbowl05.png'
import PaulBAward07Img from '../assets/awards/awards_paulb_fall_bloodbowl/paulb_fall_bloodbowl07.png'
import RichardAward01Img from '../assets/awards/awards_richardv_summeraos/richardv_summeraos_army01.png'
import RichardAward02Img from '../assets/awards/awards_richardv_summeraos/richardv_summeraos_army02.png'
import RichardAward03Img from '../assets/awards/awards_richardv_summeraos/richardv_summeraos_army03.png'
import ChristopherAward01Img from '../assets/awards/awards_christopherv_summeraos/christopherv_summeraos_army01.png'
import ChristopherAward02Img from '../assets/awards/awards_christopherv_summeraos/christopherv_summeraos_army02.png'
import ChristopherAward03Img from '../assets/awards/awards_christopherv_summeraos/christopherv_summeraos_army03.png'
import ChristopherAward04Img from '../assets/awards/awards_christopherv_summeraos/christopherv_summeraos_army04.png'
import VinceAwardImg from '../assets/awards/vince_award.jpg'

function Awards() {
  return (
    <div className={styles.contentGroup}>
      <h1>Awards</h1>
      <div className={styles.contentSubgroup}>
        <h2>Special mentions and awards</h2>
        <div className={styles.imgSubgroup}>
          <h3>Blood Bowl Chaos Cup Voted Best Theme - Fall 2023</h3>
          <div style={{marginBottom:"25px"}}> Congrats Paul B!</div>
          <div className={styles.images}>
            <img src={PaulBAward02Img} />
            <img src={PaulBAward07Img} />
            <img src={PaulBAward01Img} />
          </div>
          <div className={styles.images}>
            <img src={PaulBAward03Img} />
            <img src={PaulBAward04Img} />
            <img src={PaulBAward05Img} />
          </div>
        </div>
        <div className={styles.imgSubgroup}>
          <h3>Age Of Sigmar Voted Best Army - Summer 2023!</h3>
          <div style={{marginBottom:"25px"}}>Richard V.</div>
          <div className={styles.images}>
            <img src={RichardAward01Img} />
            <img src={RichardAward02Img} />
          </div>
          <div className={styles.images}>
            <img src={RichardAward03Img} />
          </div>
        </div>
        <div className={styles.imgSubgroup}>
        <h3>Age Of Sigmar Voted Best Army - Summer 2023!</h3>
          <div style={{marginBottom:"25px"}}>Christopher V.</div>
          <div className={styles.images}>
            <img src={ChristopherAward01Img} />
            <img src={ChristopherAward02Img} />
          </div>
          <div className={styles.images}>
            <img src={ChristopherAward03Img} />
            <img src={ChristopherAward04Img} />
          </div>
        </div>
        <div className={styles.imgSubgroup}>
          <h3>Congrats to Vince!</h3>
          <div style={{marginBottom:"25px"}}>Vince was awarded the Overall Champion medal at Adepticon! Way to go Vince!!!</div>
          <div className={styles.images}>
            <img src={VinceAwardImg} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Awards