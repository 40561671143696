import React from 'react'
import styles from '../../styles/StarWarsEvents.module.scss'

function StarWars() {
  return (
    <div className={styles.contentGroup}>
      <div className={styles.starWarsEventPage}>
        <h1>Events -  Star Wars</h1>
        <h2>Tournament</h2>
        <div className={styles.contentSubgroup}>Come back later for Star Wars Events</div>
        {/* <div className={styles.contentSubgroup}>Visit the Star Wars Legion September 23rd Tournament Gameuplink site here: <a href='https://legion.gameuplink.com/events/tactical-retreat-gaming-invite-tournament-92323/'>https://legion.gameuplink.com/events/tactical-retreat-gaming-invite-tournament-92323/</a></div> */}
        <div className={`${styles.contentPDF} ${styles.contentStarWars}`}>
          <object data="https://tacticalretreatgaming.com/pdfs/events/starwars/2023-09-23-Flyer-Star-Wars-Legion-Tournament.pdf#toolbar=0&;navpanes=0&;scrollbar=0" type="application/pdf">
            <embed src="https://tacticalretreatgaming.com/pdfs/events/starwars/2023-09-23-Flyer-Star-Wars-Legion-Tournament.pdf#toolbar=0&;navpanes=0&;scrollbar=0" type="application/pdf"/>
          </object>
        </div>
      </div>
    </div>
  )
}

export default StarWars