import React from 'react'
import styles from '../styles/EventRegistration.module.scss'
import { Form, Formik } from "formik";
import { eventRegistrationSchema } from "../schemas";
import CustomInput from "../components/CustomInput";
import $ from "jquery";


const onSubmit = async(values: any, actions: any) => {
    var customerName = `${values.firstName} ${values.lastName}`;
    var email = values.email;
    var event = 'Star Wars Tournament';

    console.log(customerName)

    var data = { customerName: customerName, email: email, event: event };

    var emailPromise = new Promise((resolve, reject) => 
      $.ajax({
        method: 'POST',
        url: `${window.location.origin}/wp-admin/admin-ajax.php?action=event_mail_submit`,
        data: data,
        success: function(msg){
          console.log("Submission Success", msg)
          actions.resetForm();
            resolve('Success');
        },
        error: function(XMLHttpRequest, textStatus, errorThrown) {
          alert("Submission Error " + XMLHttpRequest.responseXML);
          reject('Failed');
        }
      })
    );
    
  await emailPromise;
};


function EventRegistration() {
    return (
      <Formik
        initialValues={{ firstName: "", lastName: "", email: "" }}
        validationSchema={eventRegistrationSchema}
        onSubmit={onSubmit}
      >
        {({isSubmitting}) => (
          <Form>
            <div className={styles.contentGroup}>
              <h1>Event Registration</h1>
              <div className={styles.contentSubgroup}>
                <CustomInput
                  label="First Name"
                  name="firstName"
                  type="text"
                  placeholder="Enter your first name"
                />
                <CustomInput
                  label="Last Name"
                  name="lastName"
                  type="text"
                  placeholder="Enter your last name"
                />
                <CustomInput
                  label="Email"
                  name="email"
                  type="email"
                  placeholder="Enter your email"
                />
                {/* <CustomSelect
                  label="Job Type"
                  name="jobType"
                  placeholder="Please select a job"
                >
                  <option value="">Please select a job type</option>
                  <option value="developer">Developer</option>
                  <option value="designer">Designer</option>
                  <option value="manager">Product Manager</option>
                  <option value="other">Other</option>
                </CustomSelect>
      
                <CustomCheckbox type="checkbox" name="acceptedTos"/> */}
                <button disabled={isSubmitting} type="submit">Submit</button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  

export default EventRegistration