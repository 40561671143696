import React from 'react'
import styles from '../../styles/BloodBowlEvents.module.scss'

function BloodBowl() {
  return (
    <div className={styles.contentGroup}>
      <div className={styles.bloodBowlEventPage}>
          <h1>Events -  Blood Bowl</h1>
          <h2>Tournament</h2>
        <div className={styles.contentSubgroup}>Come back later for Blood Bowl Events</div>
          <div className={`${styles.contentPDF} ${styles.contentBloodBowl}`}>
            <object data="https://tacticalretreatgaming.com/pdfs/events/bloodbowl/BLOOD-BOWL-TOURNAMENT.pdf#toolbar=0&;navpanes=0&;scrollbar=0" type="application/pdf">
              <embed src="https://tacticalretreatgaming.com/pdfs/events/bloodbowl/BLOOD-BOWL-TOURNAMENT.pdf#toolbar=0&;navpanes=0&;scrollbar=0" type="application/pdf"/>
            </object>
          </div>
      </div>
    </div>
  )
}

export default BloodBowl