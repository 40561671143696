import React from 'react'
import styles from '../styles/Location.module.scss'
import TRGTopDownMap from "../assets/maps/TRG_Map.png";

function ContactUs() {
  return (
    <div className={styles.contentGroup}>
        <h1>Location</h1>
        <div className={styles.contentSubgroup}>
          <div>431 Ohio Pike Suite #164</div>
          <div style={{marginBottom: "25px"}}>Cincinnati OH, 45255</div>
          <div>We are across SR 125 from the Cherry Grove Kroger behind Starbucks near the Beechmont Racquet Club.</div>
        </div>
        <img src={TRGTopDownMap} className={styles.trgTopDownMap} />

    </div>
  )
}

export default ContactUs