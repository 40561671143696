import React from "react";
import styles from "../styles/Home.module.scss";
import TRGFront from "../assets/storeclub/TRG_Front.jpg";
import TRGStore from "../assets/storeclub/TRG_Store.jpg";
import TRGGameArea from "../assets/storeclub/TRG_Game_Area.jpg";
import DiscordIcon from "../assets/logos/Discord_logo.png";
import FacebookIcon from "../assets/logos/Facebook-logo-blue-circle-large-transparent2.png";
import TwitterXIcon from "../assets/logos/twitter_x_new_logo_square_x_icon.png";
import InstagramIcon from "../assets/logos/instagram_Logo.png";
import RedditIcon from "../assets/logos/reddit-logo.png";
import YoutubeIcon from "../assets/logos/youtube-2017-icon-logo-D1FE045118-seeklogo.com.png";
import TikTokIcon from "../assets/logos/tiktok-logo_1.png";
import { Link } from "react-router-dom";

function Home() {
  return (
    <div className={styles.contentPage}>
      <div className={styles.contentGroup}>
        <div className={styles.contentSubgroup}>
          <div className={styles.contentLeftSubgroup}>
          <div className={styles.contentBlock}>
            <div className={styles.contentStore}>
            <div className={styles.contentStoreLeft}>
              <img src={TRGStore} className={styles.storeclubLeftImg} />
              <div className={styles.contentStoreLeftInner}>
                <div style={{marginBottom:"20px"}}>
                Our shop is stocked with a large assortment of hobby supplies and miniatures. We can also place orders for any product available through our distributors.
                </div>
                <div>
                Ask the owners to demo a game or for tips on putting together and painting your miniatures.
                </div>
              </div>
              </div>
            </div>
          </div>
          <div className={styles.contentBlock}>
            <div className={styles.contentStore}>
              <div className={styles.contentStoreRight}>
              <div className={styles.contentStoreRightInner}>
                <div style={{marginBottom:"20px"}}>
                We are dedicated to giving our community the very best tabletop gaming experience! Our focus is in providing a place to play your favorite tabletop games.
                </div>
                <div>
                Our large gaming hall is equipped with 7 tournament regulation tables, with an additional 2 tables in the shop area. We regularly hold events, tournaments, and leagues.
                </div>
                </div>
                <img src={TRGGameArea} className={styles.storeclubRightImg} />
              </div>
            </div>
          </div>
          <div className={styles.contentBlock}>
            <div className={styles.contentStore}>
            <div className={styles.contentStoreLeft}>
              <img src={TRGFront} className={styles.storeclubLeftImg} />
              <div className={styles.contentStoreLeftInner}>
                <div style={{marginBottom:"20px"}}>
              Although miniatures and tabletop wargames are our passion, we do not discriminate! All gaming enthusiasts are welcome at TRG. Come down with your board game, CCGs, or roleplaying friends.
              </div>
              <div>
              We even have options to schedule outside our normal business hours!
              </div>
              </div>
              </div>
            </div>
          </div>
          <div className={styles.contentBlock}>
            <div className={styles.contentBecomeAMember}>
              <div className={styles.becomeAMember}>
                <div>
                  Become a member and get:
                </div>
                <div className={styles.becomeAMemberList}>
                  <ul>
                    <li>24/7 Gaming Area Access</li>
                    <li>Exclusive Merchandise Discount</li>
                    <li>Early Bird Tournament Registration</li>
                    <li>Priority on Limited Run Preorders</li>
                    <li>No League Fees</li>
                  </ul>
                </div>
                <div style={{ paddingTop: "25px" }}>
                  <Link to="/contact_us">Contact us</Link> about our memberships.
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className={styles.contentRightSubgroup}>
            <div className={styles.events}>
              <h3>Events</h3>
              <div className={styles.underline} style={{marginBottom:"20px"}}></div>
              <div className={styles.eventLinks}>
                <Link to="/events_age_of_sigmar">Age of Sigmar – 0 Event(s)</Link>
                <Link to="/events_blood_bowl">Blood Bowl – 0 Event(s)</Link>
                <Link to="/events_star_wars">Star Wars – 0 Event(s)</Link>
              </div>
            </div>
            <div className={styles.contentSocialMedia}>
              <h3>Social Media</h3>
              <div className={styles.underline}></div>
              <h4>Join Our Community</h4>
              <div style={{marginBottom:"10px"}}>
                <a href="https://discord.gg/9uZb27d7nM">
                  <img src={DiscordIcon} className={styles.discordImg} />
                </a>
                <a href="https://www.facebook.com/Tacticalretreatgaming">
                  <img src={FacebookIcon} className={styles.facebookImg} />
                </a>
              </div>
              <h4>Follow us</h4>
              <div>
                <a href="https://twitter.com/TacticalRGaming">
                  <img src={TwitterXIcon} className={styles.twitterImg} />
                </a>
                <a href="https://www.instagram.com/tacticalretreatgaming/">
                  <img src={InstagramIcon} className={styles.instagramImg} />
                </a>
                <a href="https://www.reddit.com/r/TacticalRetreatGaming/">
                  <img src={RedditIcon} className={styles.redditImg} />
                </a>
                <a href="https://www.youtube.com/@tacticalretreatgaming">
                  <img src={YoutubeIcon} className={styles.youtubeImg} />
                </a>
                <a href="https://www.tiktok.com/@tacticalretreatgaming">
                  <img src={TikTokIcon} className={styles.tiktokImg} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
