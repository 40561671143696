import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles/Events.module.scss'

function Events() {
  return (
    <div className={styles.contentGroup}>
      <h1>Events</h1>
      <div className={styles.links}>
        <Link to="/events_age_of_sigmar">Visit Age of Sigmar Events Page – 0 Event(s)</Link>
        <Link to="/events_blood_bowl">Visit Blood Bowl Events Page – 0 Event(s)</Link>
        <Link to="/events_star_wars">Visit Star Wars Events Page – 0 Event(s)</Link>
      </div>
    </div>
  )
}

export default Events