import { useField } from "formik";
import styles from '../styles/CustomInput.module.scss'

const CustomInput = ({ label, ...props }: any) => {
  const [field, meta, helpers] = useField(props);
  return (
    <div className={styles.contentGroup}>
      <label>{label}</label>
      <input
        {...field}
        {...props}
        className={meta.touched && meta.error ? "input-error" : ""}
      />
      {meta.touched && meta.error && <div className={styles.error}>{meta.error}</div>}
    </div>
  );
};
export default CustomInput;
