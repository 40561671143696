import './App.css'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Home from './pages/Home'
import About from './pages/About';
import Schedule from './pages/Schedule';
import Events from './pages/Events';
import EventsAgeOfSigmar from './pages/events/AgeOfSigmar';
import EventsBloodBowl from './pages/events/BloodBowl';
import EventsStarWars from './pages/events/StarWars';
import TRGCommunity from './pages/TRGCommunity';
import Awards from './pages/Awards';
import Podcasts from './pages/Podcasts';
import Location from './pages/Location';
import ContactUs from './pages/ContactUs';
import MakeAPayment from './pages/MakeAPayment';
import EventRegistration from './pages/EventRegistration'

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar/>
        <Routes>
          <Route 
            path="/"
            element={<Home />}
          />
          <Route 
            path="/schedule"
            element={<Schedule />}
          />
          <Route 
            path="/events"
            element={<Events />}
          />
          <Route 
            path="/events_age_of_sigmar"
            element={<EventsAgeOfSigmar />}
          />
          <Route 
            path="/events_blood_bowl"
            element={<EventsBloodBowl />}
          />
          <Route 
            path="/events_star_wars"
            element={<EventsStarWars />}
          />
          <Route 
            path="/trg_community"
            element={<TRGCommunity />}
          />
          <Route 
            path="/awards"
            element={<Awards />}
          />
          <Route 
            path="/podcasts"
            element={<Podcasts />}
          />
          <Route 
            path="/contact_us"
            element={<ContactUs />}
          />
          <Route 
            path="/location"
            element={<Location />}
          />
          <Route 
            path="/make_a_payment"
            element={<MakeAPayment />}
          />
          <Route 
            path="/event_registration"
            element={<EventRegistration />}
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
