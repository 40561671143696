import React from 'react'
import styles from '../styles/MakeAPayment.module.scss'
import PayPalLogo from '../assets/logos/hd-official-blue-paypal-logo.png'

function MakeAPayment() {
  return (
    <div className={styles.contentGroup}>
      <h1>Make A Payment</h1>
      <div className={styles.contentSubgroup}>
          <h2>Make a PayPal payment</h2>
          <a href="http://paypal.me/TacticalRG">
            <img src={PayPalLogo} />
          </a>   
      </div>
    </div>
  )
}

export default MakeAPayment