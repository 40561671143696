import React from 'react'
import styles from '../styles/Podcasts.module.scss'
import KentuckyFriedWargaming from "../assets/podcasts/Kentucky-fried-wargaming.jpg";

function Awards() {
  return (
    <div className={styles.contentGroup}>
      <h1>Podcasts</h1>
      <div className={styles.contentSubgroup}>
        <h3>Check out community podcasts</h3>
        <div className={styles.leftSide}>
          <img src={KentuckyFriedWargaming} className={styles.kfwImage} />
          <div>
            <div className={styles.rightSide}>
              <a href="https://podcasters.spotify.com/pod/show/kentuckyfriedwargaming">
                Kentucky Fried Wargaming Podcast on Spotify
              </a> 
            </div>
            <div className={styles.rightSide}>
              <a href="https://www.youtube.com/channel/UCanfvY6TJfsmyolgg-Chq4A">
                Kentucky Fried Wargaming YouTube
              </a> 
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Awards