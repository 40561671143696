import React, { useState } from 'react'
import Logo from '../assets/logos/TRG_Dice_Logo_NoBG.png'
import {Link} from 'react-router-dom'
import styles from '../styles/Navbar.module.scss'
import Menu from '@mui/icons-material/Menu'
import ArrorDropDownIndicator from '@mui/icons-material/ArrowDropDown'

function Navbar() {
  const [openLinks, setOpenLinks] = useState(false)
  const toggleNavbar: any = () => {
    setOpenLinks(!openLinks)
  }
  return (
    <div className={styles.navbar}>
        <div className={styles.title}>
            <img src={Logo}/>
            <h1 className={styles.trgTitle}>Tactical Retreat Gaming</h1>
        </div>
        <div className={styles.rightSide} id={openLinks?styles.open:styles.close}>
            <div className={styles.menuLinks}>
              <Link to="/">Home</Link>
              <Link to="/schedule">Schedule</Link>
              <div className={styles.eventsLink}>
                <Link to="/events">Events<ArrorDropDownIndicator/></Link>
                <div className={styles.eventsLinkMenu}>
                  <ul>
                    <li><Link to="/events_age_of_sigmar">Age of Sigmar</Link></li>
                    <li><Link to="/events_blood_bowl">Blood Bowl</Link></li>
                    <li><Link to="/events_star_wars">Star Wars</Link></li>
                  </ul>
                </div>
              </div>
              <div className={styles.trgCommunityLink}>
                <Link to="/trg_community">TRG Community<ArrorDropDownIndicator/></Link>
                <div className={styles.trgCommunityLinkMenu}>
                  <ul>
                    <li><Link to="/awards">Awards</Link></li>
                    <li><Link to="/podcasts">Podcasts</Link></li>
                  </ul>
                </div>
              </div>
              
              <Link to="/contact_us">Contact us</Link>
              <Link to="/location">Location</Link>
              <Link to="/make_a_payment">Make a Payment</Link>
            </div>
            <button onClick={()=>toggleNavbar()}>
              <Menu/>
            </button>
        </div>
    </div>
  )
}

export default Navbar