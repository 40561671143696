import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles/TRGCommunity.module.scss'

function Events() {
  return (
    <div className={styles.contentGroup}>
      <h1>TRG Community</h1>
      <div className={styles.links}>
        <Link to="/awards">Awards</Link>
        <Link to="/podcasts">Podcasts</Link>
      </div>
    </div>
  )
}

export default Events