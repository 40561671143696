import React from 'react'
import styles from '../styles/ContactUs.module.scss'

function ContactUs() {
  return (
    <div className={styles.contentGroup}>
        <h1>Contact us</h1>

        <div className={styles.contentSubgroup}>
          <h2>Store Hours</h2>
          <div>T-F 5pm-10pm and during events</div>
          <div>Saturday Noon-6pm</div>
        </div>
        <div className={styles.contentSubgroup}>
          <h2>Game Club Hours</h2>
          <div>24/7 for members</div>
          <div>Store Hours for non-members</div>
          <div>Contact us about our memberships</div>
        </div>
        <div className={styles.contentSubgroup}>
        <h2>Located</h2>
          <div>431 Ohio Pike Suite #164</div>
          <div style={{marginBottom: "25px"}}>Cincinnati OH, 45255</div>
          <div>We are across SR 125 from the Cherry Grove Kroger behind Starbucks near the Beechmont Racquet Club.</div>
        </div>
        <div className={styles.contentSubgroup}>
          <h2>Email</h2>
          <a href="mailto:contact@tacticalretreatgaming.com">contact@tacticalretreatgaming.com</a>
        </div>
        <div className={styles.contentSubgroup}>
          <h2>Phone</h2>
          <a href="tel:+15133415119">(513) 341-5119</a>
        </div>

    </div>
  )
}

export default ContactUs