import React from 'react'
import styles from '../styles/Schedule.module.scss'
import WeeklySchedule from '../assets/schedules/Weekly_Schedule.png'

function Schedule() {
  return (
    <div className={styles.contentGroup}>
      <h1>Schedule</h1>
      <img src={WeeklySchedule} />
      <figcaption>Tactical Retreat Gaming weekly schedule</figcaption>
    </div>
  )
}

export default Schedule