import React from 'react'
import styles from '../styles/Footer.module.scss'
import DiscordIcon from '../assets/logos/Discord_logo.png'
import FacebookIcon from '../assets/logos/Facebook-logo-blue-circle-large-transparent2.png'
import TwitterXIcon from "../assets/logos/twitter_x_new_logo_square_x_icon.png";
import InstagramIcon from '../assets/logos/instagram_Logo.png'
import RedditIcon from '../assets/logos/reddit-logo.png'
import YoutubeIcon from '../assets/logos/youtube-2017-icon-logo-D1FE045118-seeklogo.com.png'
import TikTokIcon from '../assets/logos/tiktok-logo_1.png'
import PayPalLogo from '../assets/logos/hd-official-blue-paypal-logo.png'

function Footer() {
  return (
    <div className={styles.footer}>
        <div className={styles.contentGroup}>
            <h1 className={styles.footerTitle}>Tactical Retreat Gaming</h1>
            <div className={styles.contentSubgroup}>
                <div className={styles.contentLeftSubgroup}>
                    <h3>Store Hours:</h3>
                    <div>T-F 5pm-10pm and during events</div>
                    <div>Saturday Noon-6pm</div>

                    <h3>Game Hall Hours:</h3>
                    <div>24/7 for members</div>
                    <div style={{marginBottom: "25px"}}>During store hours for non-members</div>
                    
                    <div>Contact us about our memberships</div>
                    <div><a href="mailto:contact@tacticalretreatgaming.com">contact@tacticalretreatgaming.com</a></div>
                    <div style={{marginBottom: "25px"}}><a href="tel:+15133415119">(513) 341-5119</a></div>
                    
                </div>
                <div className={styles.contentRightSubgroup}>
                    <div>
                        <h3>Follow us</h3>
                        <div className={styles.contentSocialMedia}>
                            <a href="https://discord.gg/9uZb27d7nM"><img src={DiscordIcon} className={styles.discordImg} /></a>
                            <a href="https://www.facebook.com/Tacticalretreatgaming"><img src={FacebookIcon}  className={styles.facebookImg} /></a>
                            <a href="https://twitter.com/TacticalRGaming"><img src={TwitterXIcon}  className={styles.twitterImg} /></a>
                            <a href="https://www.instagram.com/tacticalretreatgaming/"><img src={InstagramIcon} className={styles.instagramImg} /></a>
                            <a href="https://www.reddit.com/r/TacticalRetreatGaming/"><img src={RedditIcon} className={styles.redditImg} /></a>
                            <a href="https://www.youtube.com/@tacticalretreatgaming"><img src={YoutubeIcon} className={styles.youtubeImg} /></a>
                            <a href="https://www.tiktok.com/@tacticalretreatgaming"><img src={TikTokIcon} className={styles.tiktokImg} /></a>
                        </div>
                    </div>
                    <div className={styles.makeAPaymentSubgroup}>
                        <h3>Make a PayPal payment</h3>
                        <a href="http://paypal.me/TacticalRG">
                            <img src={PayPalLogo} />
                        </a>   
                    </div>
                </div>
            </div>
            <div className={styles.location}>
                <div>431 Ohio Pike Suite #164</div>
                <div>Cincinnati OH, 45255</div>
                <div>We are across SR 125 from the Cherry Grove Kroger behind Starbucks near the Beechmont Racquet Club.</div>
            </div>
            <div className={styles.copyRight}>&copy; Copyright 2023 — Tactical Retreat Gaming. All rights reserved.</div>
        </div>
    </div>
  )
}

export default Footer