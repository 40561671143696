import React from 'react'
import styles from '../../styles/AosEvents.module.scss'

function AgeOfSigmar() {
  return (
    <div className={styles.contentGroup}>
      <div className={styles.aosEventPage}>
        <h1>Events - Age of Sigmar</h1>
        <h2>League</h2>
        <div className={styles.contentSubgroup}>Come back later for Age of Sigmar Events</div>
        {/* <div className={styles.contentSubgroup}>TRG is hosting an Age of Sigmar League. League has started. Sign-ups are closed</div> */}
        <div className={`${styles.contentPDF} ${styles.contentAOS}`}>
          <object data="https://tacticalretreatgaming.com/pdfs/events/ageofsigmar/2023-03-24-AOS-League.pdf#toolbar=0&;navpanes=0&;scrollbar=0" type="application/pdf">
            <embed src="https://tacticalretreatgaming.com/pdfs/events/ageofsigmar/2023-03-24-AOS-League.pdf#toolbar=0&;navpanes=0&;scrollbar=0" type="application/pdf"/>
          </object>
        </div>
      </div>
    </div>
  )
}

export default AgeOfSigmar